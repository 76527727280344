import React from 'react'

import Layout from '../components/layout'
import CenterHero from '../components/hero/center_hero'
import { HomeHeroBackground } from '../components/hero/hero_background'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import { useEffect } from 'react'

const hsMeetingIframe = 'https://meetings.hubspot.com/malo-jamain?embed=true'

const hsMeetingScript = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'

const AskForDemoPage = () => {
  const [isDocumentReady, setDocumentReady] = useState(false)
  useEffect(() => {
    setDocumentReady(true)
  })
  return (
    <Layout
      helmetTitle="Book a demo today | Cryptr"
      helmetDescription="Book a demo today to find out how Cryptr can change your business"
      helmetImage={'/metatag_image_cryptr.png'}
      helmetUrl="https://www.cryptr.co/ask-for-demo/"
    >
      <HomeHeroBackground />
      <CenterHero
        title={[
          {
            spans: [],
            text: 'Request a demo of Cryptr',
            type: 'heading1',
          },
        ]}
        description={[
          {
            spans: [],
            text: 'Schedule a free live demo with our Sales team to see Cryptr in action',
            type: 'paragraph',
          },
        ]}
        textAlignment="center"
      />
      <section id="meeting-scheduling">
        {isDocumentReady && (
          <Helmet>
            <script type="text/javascript" src={hsMeetingScript}></script>
          </Helmet>
        )}
        <div className="meetings-iframe-container" data-src={hsMeetingIframe}></div>
      </section>
    </Layout>
  )
}

export default AskForDemoPage
